import { DateTime } from 'luxon';
import { BookingId, BookingRequestId, CompanyId, MealId, MemberId, PropertyId, RoomId, SearchId, StripeClientSecret, StripePaymentMethodId, TeamId, UserId } from '@shared/constants';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** BookingId custom scalar type */
  BookingId: BookingId;
  /** BookingRequestIdScalar custom scalar type */
  BookingRequestId: BookingRequestId;
  /** CompanyId custom scalar type */
  CompanyId: CompanyId;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: DateTime;
  /** MealId custom scalar type */
  MealId: MealId;
  /** MemberId custom scalar type */
  MemberId: MemberId;
  /** PropertyId custom scalar type */
  PropertyId: PropertyId;
  /** RoomId custom scalar type */
  RoomId: RoomId;
  /** SearchId custom scalar type */
  SearchId: SearchId;
  /** StripeClientSecret custom scalar type */
  StripeClientSecret: StripeClientSecret;
  /** StripePaymentMethodId custom scalar type */
  StripePaymentMethodId: StripePaymentMethodId;
  /** TeamId custom scalar type */
  TeamId: TeamId;
  /** UserId custom scalar type */
  UserId: UserId;
};

export enum Accommodation {
  Apartment = 'APARTMENT',
  Hotel = 'HOTEL'
}

export type AddMemberError = {
  __typename?: 'AddMemberError';
  errorType: AddMemberErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum AddMemberErrorType {
  AlreadyExists = 'AlreadyExists'
}

export type AddMemberInput = {
  email: Scalars['String'];
  teamId?: InputMaybe<Scalars['TeamId']>;
};

export type AddMemberResult = AddMemberError | Member;

export type AddPaymentMethodRequest = {
  __typename?: 'AddPaymentMethodRequest';
  clientSecret: Scalars['StripeClientSecret'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export enum Amenity {
  AirConditioned = 'AIR_CONDITIONED',
  Bar = 'BAR',
  DisabledFacilities = 'DISABLED_FACILITIES',
  EvCharging = 'EV_CHARGING',
  FreeWifi = 'FREE_WIFI',
  Gym = 'GYM',
  Parking = 'PARKING',
  PetFriendly = 'PET_FRIENDLY',
  Pool = 'POOL',
  Restaurant = 'RESTAURANT',
  Spa = 'SPA',
  WheelchairAccess = 'WHEELCHAIR_ACCESS'
}

export type Availability = {
  __typename?: 'Availability';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  minPrice: GuestMemberPrice;
  notes: Array<Note>;
  propertyId: Scalars['PropertyId'];
  roomTypes: Array<AvailabilityRoomType>;
};

export type AvailabilityConnection = {
  __typename?: 'AvailabilityConnection';
  edges: Array<AvailabilityEdge>;
  emissions: LocationEmissions;
  /** The edge indexes when emissions data changes. Only valid when sorting by carbon footprint */
  emissionsCount: AvailabilityEmissionsCount;
  maxPrice: GuestMemberPrice;
  minPrice: GuestMemberPrice;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  radius: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AvailabilityConnectionResult = AvailabilityConnection | AvailabilityError;

export type AvailabilityEdge = {
  __typename?: 'AvailabilityEdge';
  cursor: Scalars['String'];
  node: Availability;
};

export type AvailabilityEmissionsCount = {
  __typename?: 'AvailabilityEmissionsCount';
  aboveAvg: Scalars['Int'];
  belowAvg: Scalars['Int'];
  nonDisclosed: Scalars['Int'];
};

export type AvailabilityError = {
  __typename?: 'AvailabilityError';
  errorType: AvailabilityErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum AvailabilityErrorType {
  Unknown = 'Unknown'
}

export type AvailabilityResult = Availability | PropertyError;

export type AvailabilityRoom = {
  __typename?: 'AvailabilityRoom';
  bedCount: Scalars['Int'];
  cancellationPolicies: Array<CancellationPolicy>;
  externalId: Scalars['RoomId'];
  extraBedCount: Scalars['Int'];
  isAmendable: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  meals: Array<Meal>;
  notes: Array<Note>;
};

export type AvailabilityRoomType = {
  __typename?: 'AvailabilityRoomType';
  name: Scalars['String'];
  rooms: Array<AvailabilityRoom>;
};

export type Booking = {
  __typename?: 'Booking';
  adultCount: Scalars['Int'];
  arrivalDate: Scalars['DateTime'];
  bedCount: Scalars['Float'];
  cancellationPolicies: Array<CancellationPolicy>;
  companyId?: Maybe<Scalars['CompanyId']>;
  companyNotes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  emissions: Emissions;
  extraBedCount: Scalars['Float'];
  guests?: Maybe<Array<Guest>>;
  id: Scalars['BookingId'];
  isCancelled?: Maybe<Scalars['Boolean']>;
  meal: Scalars['String'];
  notes: Array<Note>;
  phoneNumber: Scalars['String'];
  price: Pricing;
  property: Property;
  propertyId: Scalars['PropertyId'];
  reference: Scalars['String'];
  roomCount: Scalars['Int'];
  roomName: Scalars['String'];
  teamId?: Maybe<Scalars['TeamId']>;
  voucherUrl: Scalars['String'];
};

export type BookingConnection = {
  __typename?: 'BookingConnection';
  edges: Array<BookingEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BookingConnectionResult = BookingConnection | BookingError;

export type BookingEdge = {
  __typename?: 'BookingEdge';
  cursor: Scalars['String'];
  node: Booking;
};

export type BookingError = {
  __typename?: 'BookingError';
  errorType: BookingErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum BookingErrorType {
  BookingNotFound = 'BookingNotFound',
  NotAuthorised = 'NotAuthorised'
}

export type BookingRequest = {
  __typename?: 'BookingRequest';
  adultCount: Scalars['Int'];
  arrivalDate: Scalars['DateTime'];
  bedCount: Scalars['Int'];
  bookingFailed?: Maybe<Scalars['Boolean']>;
  bookingId?: Maybe<Scalars['BookingId']>;
  cancellationPolicies: Array<CancellationPolicy>;
  clientSecret: Scalars['StripeClientSecret'];
  company?: Maybe<Company>;
  companyNotes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  emissions: Emissions;
  extraBedCount: Scalars['Int'];
  guests: Array<Guest>;
  id: Scalars['BookingRequestId'];
  isMaxSpendReached: Scalars['Boolean'];
  isMemberPrice: Scalars['Boolean'];
  isSubscriptionActive: Scalars['Boolean'];
  mealLabel?: Maybe<Scalars['String']>;
  mealName: Scalars['String'];
  notes: Array<Note>;
  paymentStatus: PaymentStatus;
  price: GuestMemberPrice;
  property: Property;
  roomCount: Scalars['Int'];
  roomName: Scalars['String'];
};

export type BookingRequestError = {
  __typename?: 'BookingRequestError';
  errorType: BookingRequestErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum BookingRequestErrorType {
  NotFound = 'NotFound'
}

export type BookingRequestGuest = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type BookingRequestResult = BookingRequest | BookingRequestError;

export type BookingResult = Booking | BookingError;

export type CancelBookingInput = {
  bookingId: Scalars['BookingId'];
  companyId?: InputMaybe<Scalars['CompanyId']>;
  teamId?: InputMaybe<Scalars['TeamId']>;
};

export type CancellationPolicy = {
  __typename?: 'CancellationPolicy';
  deadline?: Maybe<Scalars['Int']>;
  percentage: Scalars['Float'];
};

export enum CardBrand {
  Amex = 'Amex',
  Diners = 'Diners',
  Discover = 'Discover',
  Jcb = 'Jcb',
  Mastercard = 'Mastercard',
  Unionpay = 'Unionpay',
  Unknown = 'Unknown',
  Visa = 'Visa'
}

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  allowCompanyCard?: Maybe<Scalars['Boolean']>;
  emissions: Scalars['Float'];
  id: Scalars['CompanyId'];
  isOwner: Scalars['Boolean'];
  name: Scalars['String'];
  notifications: CompanyNotifications;
  paymentMethod?: Maybe<PaymentMethod>;
  teams: TeamConnection;
  vatNumber?: Maybe<Scalars['String']>;
};

export type CompanyNotifications = {
  __typename?: 'CompanyNotifications';
  billings: Array<Scalars['String']>;
  carbonReports: Array<Scalars['String']>;
};

export type CompanyOverview = {
  __typename?: 'CompanyOverview';
  id: Scalars['CompanyId'];
  member?: Maybe<Member>;
  memberEmail?: Maybe<Scalars['String']>;
  memberStatus?: Maybe<MemberStatus>;
  name: Scalars['String'];
  ownerFirstName: Scalars['String'];
  ownerLastName: Scalars['String'];
};


export type CompanyOverviewMemberArgs = {
  input: CompanyOverviewMemberInput;
};

export type CompanyOverviewInput = {
  companyId: Scalars['CompanyId'];
};

export type CompanyOverviewMemberInput = {
  memberId?: InputMaybe<Scalars['MemberId']>;
  teamId?: InputMaybe<Scalars['TeamId']>;
};

export type CompleteTutorialsInput = {
  amber?: InputMaybe<Scalars['Boolean']>;
  carbonNegative?: InputMaybe<Scalars['Boolean']>;
  green?: InputMaybe<Scalars['Boolean']>;
  red?: InputMaybe<Scalars['Boolean']>;
};

export type ConfirmedBooking = {
  __typename?: 'ConfirmedBooking';
  action?: Maybe<PaymentAction>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateCompanyInput = {
  name: Scalars['String'];
};

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type Emissions = {
  __typename?: 'Emissions';
  /** The average CO2 kg emitted by hotels in this location */
  average: Scalars['Float'];
  /** The CO2 kg emitted for a stay in this hotel. null == non-disclosed */
  hotel?: Maybe<Scalars['Float']>;
  /** The name of the location of the average */
  location: Scalars['String'];
  /** The CO2 kg offset for a stay in this hotel */
  offset: Scalars['Float'];
};

export type EmissionsInput = {
  arrivalDate: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  rooms: Scalars['Int'];
};

export type Footprint = {
  __typename?: 'Footprint';
  greenNights: Scalars['Int'];
  offset: Scalars['Float'];
};

export type GetBooking = {
  bookingId: Scalars['BookingId'];
  companyId?: InputMaybe<Scalars['CompanyId']>;
  teamId?: InputMaybe<Scalars['TeamId']>;
};

export type Guest = {
  __typename?: 'Guest';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type GuestMemberPrice = {
  __typename?: 'GuestMemberPrice';
  guest: Money;
  member: Money;
};

export type Image = {
  __typename?: 'Image';
  index: Scalars['Float'];
  url: Scalars['String'];
};

export type JoinRequestInput = {
  memberId: Scalars['MemberId'];
};

export type JoinTeamInput = {
  companyId: Scalars['CompanyId'];
  memberId: Scalars['MemberId'];
  teamId: Scalars['TeamId'];
};

export type LocationEmissions = {
  __typename?: 'LocationEmissions';
  average: Scalars['Float'];
  maxReduce: Scalars['Float'];
  name: Scalars['String'];
};

export type Meal = {
  __typename?: 'Meal';
  label?: Maybe<Scalars['String']>;
  mealId: Scalars['MealId'];
  name?: Maybe<Scalars['String']>;
  price: GuestMemberPrice;
};

export enum MealType {
  AllInclusive = 'ALL_INCLUSIVE',
  Breakfast = 'BREAKFAST',
  FullBoard = 'FULL_BOARD',
  HalfBoard = 'HALF_BOARD',
  None = 'NONE'
}

export type Member = {
  __typename?: 'Member';
  company: Company;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['MemberId'];
  lastName?: Maybe<Scalars['String']>;
  status: MemberStatus;
  team: Team;
  user?: Maybe<User>;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  edges: Array<MemberEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MemberEdge = {
  __typename?: 'MemberEdge';
  cursor: Scalars['String'];
  node: Member;
};

export type MemberInput = {
  companyId: Scalars['CompanyId'];
  memberId: Scalars['MemberId'];
  teamId: Scalars['TeamId'];
};

export enum MemberStatus {
  Active = 'Active',
  Pending = 'Pending',
  Requested = 'Requested'
}

export type MembersInput = {
  cursor?: InputMaybe<Scalars['String']>;
  take: Scalars['Float'];
  teamId?: InputMaybe<Scalars['TeamId']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Currency;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptJoinRequest: Member;
  addMember: AddMemberResult;
  cancelBooking: Booking;
  completeTutorials: User;
  confirmBookingRequest: ConfirmedBooking;
  createCompany: Company;
  joinTeam: Member;
  leaveTeam: Member;
  removeMember: Scalars['Boolean'];
  removePaymentMethod: Company;
  requestBooking: RequestBookingResult;
  resendTeamInvite: Scalars['Boolean'];
  rootMutation: Scalars['String'];
  sendJoinRequest: Member;
  updateBookingRequest: BookingRequest;
  updateCompany: Company;
  updateUser: User;
};


export type MutationAcceptJoinRequestArgs = {
  input: JoinRequestInput;
};


export type MutationAddMemberArgs = {
  input: AddMemberInput;
};


export type MutationCancelBookingArgs = {
  input: CancelBookingInput;
};


export type MutationCompleteTutorialsArgs = {
  input: CompleteTutorialsInput;
};


export type MutationConfirmBookingRequestArgs = {
  id: Scalars['BookingRequestId'];
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationJoinTeamArgs = {
  input: JoinTeamInput;
};


export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};


export type MutationRequestBookingArgs = {
  data: RequestBooking;
};


export type MutationResendTeamInviteArgs = {
  input: ResendTeamInviteInput;
};


export type MutationSendJoinRequestArgs = {
  input: SendJoinRequestInput;
};


export type MutationUpdateBookingRequestArgs = {
  input: UpdateBookingRequestInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUser;
};

export type Note = {
  __typename?: 'Note';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  text: Scalars['String'];
};

export type PageCursor = {
  __typename?: 'PageCursor';
  cursor: Scalars['String'];
  index: Scalars['Int'];
  isCurrent: Scalars['Boolean'];
};

export type PageCursors = {
  __typename?: 'PageCursors';
  around: Array<PageCursor>;
  first?: Maybe<PageCursor>;
  last?: Maybe<PageCursor>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type PaymentAction = {
  __typename?: 'PaymentAction';
  clientSecret: Scalars['StripeClientSecret'];
  paymentMethodId: Scalars['StripePaymentMethodId'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand: CardBrand;
  digits: Scalars['String'];
  expireMonth: Scalars['Int'];
  expireYear: Scalars['Int'];
};

export enum PaymentStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Idle = 'Idle',
  Processing = 'Processing'
}

export type Pricing = {
  __typename?: 'Pricing';
  amount: Money;
  cost: Money;
};

export type Property = {
  __typename?: 'Property';
  address: Address;
  amenities: Array<Scalars['String']>;
  availability: AvailabilityResult;
  descriptions: Array<Text>;
  emissions: Emissions;
  headlines: Array<Text>;
  id: Scalars['PropertyId'];
  images: Array<Image>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  starRating?: Maybe<Scalars['Int']>;
};


export type PropertyAvailabilityArgs = {
  filter: PropertyFilter;
};


export type PropertyEmissionsArgs = {
  input: EmissionsInput;
};


export type PropertyImagesArgs = {
  take?: InputMaybe<Scalars['Int']>;
};

export type PropertyError = {
  __typename?: 'PropertyError';
  errorType: PropertyErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum PropertyErrorType {
  PropertyNotAvailable = 'PropertyNotAvailable',
  PropertyNotFound = 'PropertyNotFound',
  Unknown = 'Unknown'
}

export type PropertyFilter = {
  adults: Scalars['Int'];
  arrivalDate: Scalars['DateTime'];
  currency?: InputMaybe<Currency>;
  departureDate: Scalars['DateTime'];
  rooms: Scalars['Int'];
};

export type PropertyInput = {
  id?: InputMaybe<Scalars['PropertyId']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PropertyResult = Property | PropertyError;

export type Query = {
  __typename?: 'Query';
  addPaymentMethod: AddPaymentMethodRequest;
  availability: AvailabilityConnectionResult;
  booking: BookingResult;
  bookingRequest: BookingRequestResult;
  bookings: BookingConnectionResult;
  company: Company;
  companyOverview: CompanyOverview;
  currency?: Maybe<Currency>;
  currentUser?: Maybe<User>;
  customerPortal?: Maybe<Scalars['String']>;
  footprint: Footprint;
  member: Member;
  members: MemberConnection;
  property: PropertyResult;
  propertyAvailability: AvailabilityResult;
  rootQuery: Scalars['String'];
  subscribe: SubscribeResult;
  team: Team;
  tutorials?: Maybe<Tutorials>;
};


export type QueryAvailabilityArgs = {
  filter: SearchFilter;
};


export type QueryBookingArgs = {
  args: GetBooking;
};


export type QueryBookingRequestArgs = {
  id: Scalars['BookingRequestId'];
};


export type QueryBookingsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyOverviewArgs = {
  input: CompanyOverviewInput;
};


export type QueryMemberArgs = {
  input: MemberInput;
};


export type QueryMembersArgs = {
  input: MembersInput;
};


export type QueryPropertyArgs = {
  input: PropertyInput;
};


export type QueryPropertyAvailabilityArgs = {
  filter: PropertyFilter;
  id: Scalars['ID'];
};


export type QuerySubscribeArgs = {
  options: Subscribe;
};


export type QueryTeamArgs = {
  input: TeamInput;
};

export type RemoveMemberInput = {
  memberId: Scalars['MemberId'];
};

export type RequestBooking = {
  adultCount: Scalars['Int'];
  arrivalDate: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  mealId: Scalars['MealId'];
  roomCount: Scalars['Int'];
  roomId: Scalars['RoomId'];
  searchId: Scalars['SearchId'];
};

export type RequestBookingError = {
  __typename?: 'RequestBookingError';
  errorType: RequestBookingErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum RequestBookingErrorType {
  ProfileIncomplete = 'ProfileIncomplete',
  PropertyNotAvailable = 'PropertyNotAvailable',
  SearchNotFound = 'SearchNotFound',
  Unknown = 'Unknown'
}

export type RequestBookingResult = BookingRequest | RequestBookingError;

export type ResendTeamInviteInput = {
  memberId: Scalars['MemberId'];
};

export type SearchFilter = {
  accommodations?: InputMaybe<Array<Accommodation>>;
  adults: Scalars['Int'];
  amenities?: InputMaybe<Array<Amenity>>;
  arrivalDate: Scalars['DateTime'];
  autoZoom: Scalars['Boolean'];
  currency?: InputMaybe<Currency>;
  departureDate: Scalars['DateTime'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maxDistance?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  meals?: InputMaybe<Array<MealType>>;
  minPrice?: InputMaybe<Scalars['Int']>;
  refundable?: InputMaybe<Scalars['Boolean']>;
  rooms: Scalars['Int'];
  sortBy: SortBy;
  sortOrder: SortOrder;
  starRatings?: InputMaybe<Array<Scalars['Int']>>;
};

export type SendJoinRequestInput = {
  companyId: Scalars['CompanyId'];
  teamId: Scalars['TeamId'];
};

export type SetAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
};

export enum SortBy {
  Carbon = 'Carbon',
  Price = 'Price'
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type Subscribe = {
  period: SubscriptionPeriod;
  plan: SubscriptionPlan;
};

export type SubscribeError = {
  __typename?: 'SubscribeError';
  errorType: SubscribeErrorType;
  message?: Maybe<Scalars['String']>;
};

export enum SubscribeErrorType {
  Unknown = 'Unknown'
}

export type SubscribeResult = SubscribeError | SubscriptionRequest;

export enum SubscriptionPeriod {
  Annual = 'Annual',
  Monthly = 'Monthly'
}

export enum SubscriptionPlan {
  Scale = 'Scale',
  Starter = 'Starter'
}

export type SubscriptionRequest = {
  __typename?: 'SubscriptionRequest';
  checkoutUrl: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  company?: Maybe<Company>;
  id: Scalars['TeamId'];
  members: MemberConnection;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  edges: Array<TeamEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  cursor: Scalars['String'];
  node: Team;
};

export type TeamInput = {
  id: Scalars['TeamId'];
};

export type Text = {
  __typename?: 'Text';
  languageCode: Scalars['String'];
  text: Scalars['String'];
};

export type Tutorials = {
  __typename?: 'Tutorials';
  amber: Scalars['Boolean'];
  carbonNegative: Scalars['Boolean'];
  green: Scalars['Boolean'];
  red: Scalars['Boolean'];
};

export type UpdateBookingRequestInput = {
  companyNotes?: InputMaybe<Scalars['String']>;
  guests?: InputMaybe<Array<BookingRequestGuest>>;
  id: Scalars['BookingRequestId'];
};

export type UpdateCompanyEmissions = {
  budget?: InputMaybe<Scalars['Int']>;
  spend?: InputMaybe<Scalars['Int']>;
  travellers?: InputMaybe<Scalars['Float']>;
  trips?: InputMaybe<Scalars['Float']>;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<SetAddressInput>;
  allowCompanyCard?: InputMaybe<Scalars['Boolean']>;
  emissions?: InputMaybe<UpdateCompanyEmissions>;
  name?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<UpdateCompanyNotifications>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyNotifications = {
  billings: Array<Scalars['String']>;
  carbonReports: Array<Scalars['String']>;
};

export type UpdateUser = {
  currency?: InputMaybe<Currency>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Currency>;
  email: Scalars['String'];
  emissions: Scalars['Float'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UserId'];
  isMember: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  pendingInvite?: Maybe<Member>;
  phoneNumber?: Maybe<Scalars['String']>;
  tutorials?: Maybe<Tutorials>;
};
