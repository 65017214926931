import { NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { scalarTypePolicies } from '@generated/type-policies';
import generatedIntrospection from '@generated/unions';
import { TutorialsFragment } from '@shared/operations/fragments/tutorials.generated';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { tutorialsVar } from './cache';

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        httpLink: HttpLink,
        firebaseAuth: AngularFireAuth,
      ): ApolloClientOptions<any> => {
        const basic = setContext((operation, context) => ({
          headers: {
            Accept: 'charset=utf-8',
          },
        }));

        // Get the authentication token from local storage if it exists
        const auth = setContext(async (operation, context) => {
          const token = await firebaseAuth.idToken.pipe(take(1)).toPromise();

          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        });

        const link = ApolloLink.from([
          basic,
          auth,
          httpLink.create({ uri: `${environment.api}/graphql` }),
        ]);

        return {
          link,
          cache: new InMemoryCache({
            possibleTypes: generatedIntrospection.possibleTypes,
            typePolicies: {
              ...scalarTypePolicies,
              User: {
                ...scalarTypePolicies.User,
                fields: {
                  ...scalarTypePolicies.User.fields,
                  tutorials: {
                    merge(_, incoming: TutorialsFragment) {
                      tutorialsVar(incoming);
                      return incoming;
                    },
                  },
                },
              },
              Query: {
                fields: {
                  tutorials: {
                    read() {
                      return {
                        ...tutorialsVar(),
                        __typename: 'Tutorials',
                      };
                    },
                  },
                },
              },
            },
          }),
          connectToDevTools: environment.isLocal,
        };
      },
      deps: [HttpLink, AngularFireAuth],
    },
  ],
})
export class GraphQLModule {}
