import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentUserGQL } from '@shared/operations/user.generated';
import {
  filter,
  map,
  shareReplay,
  startWith,
  switchMapTo,
} from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { LayoutService } from '../services/layout/layout.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
  readonly isSubscribed$ = this.auth.authState$.pipe(
    filter(Boolean),
    switchMapTo(
      this.currentUserGQL.watch({}, { fetchPolicy: 'cache-only' }).valueChanges,
    ),
    map(user => user.data.currentUser?.isMember),
    startWith(true),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private readonly auth: AuthService,
    private readonly currentUserGQL: CurrentUserGQL,
    readonly layout: LayoutService,
  ) {}

  doesPathMatch(path: string[]): boolean {
    return path.includes(location.pathname);
  }
}
