import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

export const GOOGLE_MAPS_SERVICE_CONFIG = new InjectionToken<string>(
  'GOOGLE_MAPS_SERVICE_CONFIG',
);

const BASE_MAPS_URL = 'https://maps.googleapis.com/maps/api/js';

const LOAD_MAPS_OPTIOND = 'libraries=places,geometry';

export interface GoogleMapsServiceConfig {
  googleMapsApiKey: string;
  mapId: string;
}

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  readonly isLoaded$: Observable<boolean>;

  /** Resolves location data about a place, location, or address */
  private readonly geocoder$: Observable<google.maps.Geocoder>;

  /** Autocomplete search query to a place */
  private autocompleter?: google.maps.places.AutocompleteService;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(PLATFORM_ID) readonly platformId: {},
    @Inject(GOOGLE_MAPS_SERVICE_CONFIG) config: GoogleMapsServiceConfig,
  ) {
    const URL = `${BASE_MAPS_URL}?key=${config.googleMapsApiKey}&${LOAD_MAPS_OPTIOND}&map_ids=${config.mapId}`;

    if (isPlatformBrowser(platformId)) {
      this.isLoaded$ = this.httpClient.jsonp(URL, 'callback').pipe(
        map(() => true),
        shareReplay(1),
      );

      this.geocoder$ = this.isLoaded$.pipe(
        map(() => new google.maps.Geocoder()),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

      this.isLoaded$.pipe(take(1)).subscribe(isLoaded => {
        if (isLoaded) {
          this.autocompleter = new google.maps.places.AutocompleteService();
        }
      });
    }
  }

  async autocomplete(
    input: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise(resolve => {
      if (!input || !this.autocompleter) {
        return resolve([]);
      }

      this.autocompleter.getPlacePredictions(
        { input },
        (predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return resolve([]);
          } else if (predictions) {
            return resolve(predictions);
          }

          return resolve([]);
        },
      );
    });
  }

  /** Returns the lat/lng coords for the provided Google Places `placeId`. */
  async geocode(placeId?: string): Promise<{
    lat: number;
    lng: number;
    radius: number;
    address: google.maps.GeocoderAddressComponent[];
    formattedAddress: string;
    types: string[];
  }> {
    const geocoder = await this.geocoder$.toPromise();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();

          const radius =
            google.maps.geometry.spherical.computeDistanceBetween(
              results[0].geometry.viewport.getNorthEast(),
              results[0].geometry.viewport.getSouthWest(),
            ) / 2;

          resolve({
            lat,
            lng,
            radius,
            address: results[0].address_components,
            formattedAddress: results[0].formatted_address,
            types: results[0].types,
          });
        } else {
          reject(`Unable to locate place ID with status: ${status}`);
        }
      });
    });
  }
}
