import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

const redirectUnauthorizedToLogin = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  if (state.url === '/login') {
    return redirectUnauthorizedTo('/login');
  } else {
    const success = encodeURIComponent(state.url);
    return redirectUnauthorizedTo(`/login?redirectUrl=${success}`);
  }
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('../bookings/bookings.module').then(m => m.BookingsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('../checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'pricing/barmy',
    loadChildren: () =>
      import('../pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'pricing/ja-alumni',
    loadChildren: () =>
      import('../pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('../profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'property/:propertyId',
    pathMatch: 'full',
    loadChildren: () =>
      import('../property/property.module').then(m => m.PropertyModule),
  },
  {
    path: 'results',
    pathMatch: 'full',
    loadChildren: () =>
      import('../results/results.module').then(m => m.ResultsModule),
  },
  {
    path: 'search',
    pathMatch: 'full',
    loadChildren: () =>
      import('../search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'team',
    loadChildren: () =>
      import('../company/company.module').then(m => m.CompanyModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'company',
    loadChildren: () =>
      import('../company-onboarding/company-onboarding.module').then(
        m => m.CompanyOnboardingModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
