import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import '@stripe/stripe-js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://00a2c30ef4ea49ef90297aa7c276b97c@o491662.ingest.sentry.io/5930700',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.api],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
  // monitoring. We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0 : 1,
});

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
