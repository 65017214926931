import { Inject, Injectable, InjectionToken } from '@angular/core';
import { UserFragment } from '@shared/operations/user.generated';
import mixpanel from 'mixpanel-browser';
import { GAEvent } from './events';

export const ANALYTICS_SERVICE_CONFIG = new InjectionToken<string>(
  'ANALYTICS_SERVICE_CONFIG',
);

export interface AnalyticsServiceConfig {
  project: string;
  isLocal: boolean;
  mixpanelToken: string;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    @Inject(ANALYTICS_SERVICE_CONFIG)
    private readonly config: AnalyticsServiceConfig,
  ) {
    if (!this.config.isLocal) {
      mixpanel.init(this.config.mixpanelToken);

      mixpanel.register({
        project: this.config.project,
      });
    }
  }

  set user(user: UserFragment | undefined) {
    if (this.config.isLocal) {
      console.info('Mixpanel user ID: ', user?.id);
    } else {
      mixpanel.identify(user?.email);
      gtag('set', { user_id: user?.id });
    }
  }

  /**
   * Aliases a users previously anonymous Mixpanel ID to their new Firebase ID.
   */
  set alias(alias: string) {
    if (this.config.isLocal) {
      console.info('Aliasing Mixpanel ID: ', alias);
    } else {
      mixpanel.alias(alias);
    }
  }

  setSuperProperty(key: string, value: string): void {
    if (this.config.isLocal) {
      console.info(`Registering Mixpanel super property: { ${key}: ${value}}`);
    } else {
      mixpanel.register({ [key]: value });
    }
  }

  reset(): void {
    if (this.config.isLocal) {
      console.info('Resetting Mixpanel');
    } else {
      mixpanel.reset();
    }
  }

  updateUserProfile(data: { [key: string]: string | number }): void {
    if (this.config.isLocal) {
      console.info(
        'Mixpanel user profile set: ',
        JSON.stringify(data, null, 2),
      );
    } else {
      mixpanel.people.set(data);
    }
  }

  track(event: GAEvent | string, data = {}): void {
    if (this.config.isLocal) {
      console.info(event, JSON.stringify(data, null, 2));
    } else if (typeof event === 'object') {
      mixpanel.track(event.event, event);
      gtag('event', event.event, event);
    } else {
      mixpanel.track(event, data);
      gtag('event', event, data);
    }
  }

  trackPageView(data: {
    path: string;
    params: { [key: string]: any };
    queryParams: { [key: string]: any };
  }): void {
    if (this.config.isLocal) {
      console.info('page_view', JSON.stringify(data, null, 2));
    } else {
      // Start with a slash so that the home page path has a '/' value.
      // Without this, the page cannot be filtered for in Mixpanel.
      this.setSuperProperty('page', data.path);

      mixpanel.track('Page view', {
        ...data.params,
        ...data.queryParams,
      });

      gtag('event', 'virtual_page_view', {
        page_route: data.path,
        page_params: data.params,
        page_query: data.queryParams,
      });

      gtag('event', 'optimize.activate');
    }
  }
}
