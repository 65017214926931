import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../services/auth/auth.module';
import { CurrencySelectModule } from '../shared-components/currency-select/currency-select.module';
import { NavBarComponent } from './nav-bar.component';

@NgModule({
  declarations: [NavBarComponent],
  imports: [
    AuthModule,
    CommonModule,
    CurrencySelectModule,
    RouterModule,
    MatButtonModule,
  ],
  exports: [NavBarComponent],
})
export class NavBarModule {}
