import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  update(args: { title?: string; desc?: string; image?: string }): void {
    const { title, desc, image } = args;

    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ name: 'twitter:title', content: title });
    }

    if (desc) {
      this.meta.updateTag({ name: 'description', content: desc });
      this.meta.updateTag({ name: 'twitter:description', content: desc });
      this.meta.updateTag({ property: 'og:description', content: desc });
    }

    if (image) {
      this.meta.updateTag({ name: 'og:image', content: image });
    }
  }
}
