import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';

export interface ILayout {
  // Show all menu links
  showMenu: boolean;

  // Show the entire navbar
  showNavbar: boolean;

  // Show the footer
  showFooter: boolean;

  // Show the currency in the nav bar
  showCurrency: boolean;
}

export const DEFAUL_LAYOUT: ILayout = {
  showNavbar: true,
  showMenu: true,
  showFooter: true,
  showCurrency: true,
};

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  /** The internal state of the layout */
  private readonly state$ = new BehaviorSubject<ILayout>(DEFAUL_LAYOUT);

  /** The previous value used to undo */
  private previous = DEFAUL_LAYOUT;

  readonly config$ = this.state$.pipe(startWith(DEFAUL_LAYOUT));

  /** Add a new layout state to the stack */
  set(input: ILayout): void {
    this.previous = this.state$.value;
    this.state$.next(input);
  }

  /** Update to the previous layout. Undo won't work multiple times in a row */
  undo(): void {
    this.set(this.previous);
  }
}
