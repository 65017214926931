
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AddMemberResult": [
      "AddMemberError",
      "Member"
    ],
    "AvailabilityConnectionResult": [
      "AvailabilityConnection",
      "AvailabilityError"
    ],
    "AvailabilityResult": [
      "Availability",
      "PropertyError"
    ],
    "BookingConnectionResult": [
      "BookingConnection",
      "BookingError"
    ],
    "BookingRequestResult": [
      "BookingRequest",
      "BookingRequestError"
    ],
    "BookingResult": [
      "Booking",
      "BookingError"
    ],
    "PropertyResult": [
      "Property",
      "PropertyError"
    ],
    "RequestBookingResult": [
      "BookingRequest",
      "RequestBookingError"
    ],
    "SubscribeResult": [
      "SubscribeError",
      "SubscriptionRequest"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddMemberResult": [
      "AddMemberError",
      "Member"
    ],
    "AvailabilityConnectionResult": [
      "AvailabilityConnection",
      "AvailabilityError"
    ],
    "AvailabilityResult": [
      "Availability",
      "PropertyError"
    ],
    "BookingConnectionResult": [
      "BookingConnection",
      "BookingError"
    ],
    "BookingRequestResult": [
      "BookingRequest",
      "BookingRequestError"
    ],
    "BookingResult": [
      "Booking",
      "BookingError"
    ],
    "PropertyResult": [
      "Property",
      "PropertyError"
    ],
    "RequestBookingResult": [
      "BookingRequest",
      "RequestBookingError"
    ],
    "SubscribeResult": [
      "SubscribeError",
      "SubscriptionRequest"
    ]
  }
};
      export default result;
    