import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  IpLocationService,
  IpLocationServiceConfig,
  IP_LOCATION_SERVICE_CONFIG,
} from './ip-location.service';

@NgModule({
  providers: [IpLocationService],
  imports: [HttpClientModule],
})
export class IpLocationModule {
  static withOptions(
    config: IpLocationServiceConfig,
  ): ModuleWithProviders<IpLocationModule> {
    return {
      ngModule: IpLocationModule,
      providers: [
        IpLocationService,
        {
          provide: IP_LOCATION_SERVICE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
