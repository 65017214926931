export const environment = {
  project: 'App',
  trackHotjar: true,
  production: true,
  isLocal: false,
  api: 'https://api-3ra3ahccma-nw.a.run.app',
  websiteDomain: 'https://staze.com',
  googleMapsApiKey: 'AIzaSyDSpa-rdlEHfIGTjUVXAXT0j83myijcNXs',
  mixpanelToken: '6741874caf685a3b1f9c5be2b08c331e',
  mapId: '2e1e315407bd5d5c',
  firebaseConfig: {
    apiKey: 'AIzaSyBU8efFn5874qVJHP2KN5zoJQ5N_La5P6o',
    authDomain: 'book.staze.com',
    projectId: 'staze-prod',
    storageBucket: 'staze-prod.appspot.com',
    messagingSenderId: '299759751504',
    appId: '1:299759751504:web:0c766062e03d3fcaea9c55',
    measurementId: 'G-FQDYC3K21K',
  },
  ipAddressLocationService:
    'https://europe-west2-staze-prod.cloudfunctions.net/getIpAddressLocation',
  stripePublishableKey: 'pk_live_OKLSwOx0yxopRdq37CmwwR9B00kdN03dvI',
};
