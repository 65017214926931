import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';

export const IP_LOCATION_SERVICE_CONFIG = new InjectionToken<string>(
  'IP_LOCATION_SERVICE_CONFIG',
);

export interface IpLocationServiceConfig {
  ipAddressLocationService: string;
}

export interface IpAddressLocation {
  countryCode?: CountryCode;
  currency?: string;
  region: string;
  city: string;
  cityLatLong: string;
  userIP: string;
}

@Injectable({
  providedIn: 'root',
})
export class IpLocationService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(IP_LOCATION_SERVICE_CONFIG)
    private readonly config: IpLocationServiceConfig,
  ) {}

  /** Uses the devices IP address to lookup the users country and currency */
  getIpLocation(): Promise<IpAddressLocation> {
    return this.httpClient
      .get<IpAddressLocation>(this.config.ipAddressLocationService)
      .toPromise();
  }
}
