import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/min';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(input: string, country = 'GB'): any {
    const phoneValue = (input || '').replace(/[^0-9+()-\s]/g, '');

    try {
      const phoneNumber = parsePhoneNumber(
        phoneValue + '',
        country as CountryCode,
      );
      return phoneNumber.formatInternational();
    } catch (error) {
      return phoneValue;
    }
  }
}
