import { Pipe, PipeTransform } from '@angular/core';
import { Money } from '@generated/types';

@Pipe({ name: 'priceVAT' })
export class PriceVATPipe implements PipeTransform {
  transform({ amount, cost }: { amount: Money; cost: Money }): Money {
    const commission = amount.amount - cost.amount;
    return {
      amount: commission - commission / 1.2,
      currency: amount.currency,
    };
  }
}
