import { Pipe, PipeTransform } from '@angular/core';

export enum EmissionsColor {
  Green = 'green',
  Amber = 'amber',
  Red = 'red',
}

@Pipe({ name: 'emissionsColor' })
export class EmissionsColorPipe implements PipeTransform {
  transform(value: { hotel?: number | null; average: number }): EmissionsColor {
    if (value.hotel === null || value.hotel === undefined) {
      return EmissionsColor.Red;
    }

    if (value.hotel < value.average) {
      return EmissionsColor.Green;
    }

    return EmissionsColor.Amber;
  }
}
