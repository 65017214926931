import * as Types from '../../../../../../shared/generated/types';

import { DateTime } from 'luxon';
import { BookingId, BookingRequestId, CompanyId, MealId, MemberId, PropertyId, RoomId, SearchId, StripeClientSecret, StripePaymentMethodId, TeamId, UserId } from '@shared/constants';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateCurrencyMutationVariables = Types.Exact<{
  currency: Types.Currency;
}>;


export type UpdateCurrencyMutation = { updateUser: { id: UserId, currency?: Types.Currency | null } };

export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($currency: Currency!) {
  updateUser(data: {currency: $currency}) {
    id
    currency
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrencyGQL extends Apollo.Mutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables> {
    document = UpdateCurrencyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }