import * as Types from '../../generated/types';

import { DateTime } from 'luxon';
import { BookingId, BookingRequestId, CompanyId, MealId, MemberId, PropertyId, RoomId, SearchId, StripeClientSecret, StripePaymentMethodId, TeamId, UserId } from '@shared/constants';
import { gql } from 'apollo-angular';
export type TutorialsFragment = { green: boolean, amber: boolean, red: boolean, carbonNegative: boolean };

export const TutorialsFragmentDoc = gql`
    fragment Tutorials on Tutorials {
  green
  amber
  red
  carbonNegative
}
    `;