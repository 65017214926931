import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Currency } from '@generated/types';
import { IpLocationService } from '@shared/modules/ip-location/ip-location.service';
import { CurrentUserGQL } from '@shared/operations/user.generated';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { UnreachableCaseError } from 'ts-essentials';
import { LocalStorageKey } from '../../app/cache';
import { UpdateCurrencyGQL } from './graphql/update-currency.generated';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  private readonly userQuery = this.currentUser.watch(
    {},
    { fetchPolicy: 'cache-only' },
  );

  private readonly value$ = new Subject<Currency>();

  readonly currency$ = this.value$.pipe(distinctUntilChanged(), shareReplay(1));

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly ipLocation: IpLocationService,
    private readonly currentUser: CurrentUserGQL,
    private readonly updateCurrency: UpdateCurrencyGQL,
  ) {
    const storedCurrency = localStorage.getItem(
      LocalStorageKey.Currency,
    ) as Currency | null;

    this.userQuery.valueChanges.subscribe(async res => {
      const user = res.data.currentUser;
      const queryParams = route.snapshot.queryParams;
      const userCurrency = user?.currency;
      const urlCurrency = this.parseCurrency(queryParams.currency);

      let currency = Currency.Usd;

      if (userCurrency) {
        currency = userCurrency;
      } else if (urlCurrency) {
        currency = urlCurrency;
      } else if (storedCurrency) {
        currency = storedCurrency;
      } else {
        const location = await this.ipLocation.getIpLocation();
        const ipCurrency = this.parseCurrency(location?.currency ?? '');
        if (ipCurrency) {
          currency = ipCurrency;
        }
      }

      // Update local storage curency
      localStorage.setItem(LocalStorageKey.Currency, currency);

      // Remove the query parameter value
      if (urlCurrency) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { currency: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }

      // Save the currency to the user if it is different or doesn't exist
      if (user && userCurrency !== currency) {
        await this.updateCurrency.mutate({ currency }).toPromise();
      }

      this.value$.next(currency);
    });
  }

  /** Set the currency and save to the user */
  async set(currency: Currency): Promise<void> {
    const isAuthenticated = await this.currentUser
      .fetch({}, { fetchPolicy: 'cache-only' })
      .pipe(map(user => !!user.data.currentUser))
      .toPromise();

    if (isAuthenticated) {
      await this.updateCurrency.mutate({ currency }).toPromise();
    }

    localStorage.setItem(LocalStorageKey.Currency, currency);

    this.value$.next(currency);
  }

  private parseCurrency(value: string): Currency | undefined {
    try {
      return this.pickCurrency(value as Currency);
    } catch {
      return undefined;
    }
  }

  private pickCurrency(value: Currency): Currency {
    switch (value) {
      case Currency.Aud:
        return Currency.Aud;
      case Currency.Cad:
        return Currency.Cad;
      case Currency.Eur:
        return Currency.Eur;
      case Currency.Gbp:
        return Currency.Gbp;
      case Currency.Usd:
        return Currency.Usd;
      default:
        throw new UnreachableCaseError(value);
    }
  }
}
