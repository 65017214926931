<ng-container *ngIf="currency$ | async as currency">
  <mat-select trigger="trigger" [value]="currency" (selectionChange)="setCurrency($event.value)">
    <mat-option [value]="currencies.Aud">
      <div class="flag">
        <svg role="img" alt="Info" aria-hidden="true">
          <use href="/assets/icons/icons.svg#flag-aus"/>
        </svg>
      </div>
      <span class="currency-code">{{ currencies.Aud }}</span>
    </mat-option>

    <mat-option [value]="currencies.Cad">
      <div class="flag">
        <svg role="img" alt="Info" aria-hidden="true">
          <use href="/assets/icons/icons.svg#flag-ca"/>
        </svg>
      </div>
      <span class="currency-code">{{ currencies.Cad }}</span>
    </mat-option>

    <mat-option [value]="currencies.Eur">
      <div class="flag">
        <svg role="img" alt="Info" aria-hidden="true">
          <use href="/assets/icons/icons.svg#flag-eur"/>
        </svg>
      </div>
      <span class="currency-code">{{ currencies.Eur }}</span>
    </mat-option>

    <mat-option [value]="currencies.Gbp">
      <div class="flag">
        <svg role="img" alt="Info" aria-hidden="true">
          <use href="/assets/icons/icons.svg#flag-uk"/>
        </svg>
      </div>
      <span class="currency-code">{{ currencies.Gbp }}</span>
    </mat-option>

    <mat-option [value]="currencies.Usd">
      <div class="flag">
        <svg role="img" alt="Info" aria-hidden="true">
          <use href="/assets/icons/icons.svg#flag-us"/>
        </svg>
      </div>
      <span class="currency-code">{{ currencies.Usd }}</span>
    </mat-option>

    <mat-select-trigger>
      <button>
        <ng-container *ngIf="currency === currencies.Aud">
          <svg role="img" alt="Info" aria-hidden="true">
            <use href="/assets/icons/icons.svg#flag-aus"/>
          </svg>
        </ng-container>
        <ng-container *ngIf="currency === currencies.Cad">
          <svg role="img" alt="Info" aria-hidden="true">
            <use href="/assets/icons/icons.svg#flag-ca"/>
          </svg>
        </ng-container>
        <ng-container *ngIf="currency === currencies.Eur">
          <svg role="img" alt="Info" aria-hidden="true">
            <use href="/assets/icons/icons.svg#flag-eur"/>
          </svg>
        </ng-container>
        <ng-container *ngIf="currency === currencies.Usd">
          <svg role="img" alt="Info" aria-hidden="true">
            <use href="/assets/icons/icons.svg#flag-us"/>
          </svg>
        </ng-container>
        <ng-container *ngIf="currency === currencies.Gbp">
          <svg role="img" alt="Info" aria-hidden="true">
            <use href="/assets/icons/icons.svg#flag-uk"/>
          </svg>
        </ng-container>
      </button>
    </mat-select-trigger>
  </mat-select>
</ng-container>
