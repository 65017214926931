import * as Types from '../generated/types';

import { DateTime } from 'luxon';
import { BookingId, BookingRequestId, CompanyId, MealId, MemberId, PropertyId, RoomId, SearchId, StripeClientSecret, StripePaymentMethodId, TeamId, UserId } from '@shared/constants';
import { gql } from 'apollo-angular';
import { TutorialsFragmentDoc } from './fragments/tutorials.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserFragment = { id: UserId, email: string, firstName?: string | null, lastName?: string | null, createdAt: DateTime, phoneNumber?: string | null, currency?: Types.Currency | null, isMember: boolean, tutorials?: { green: boolean, amber: boolean, red: boolean, carbonNegative: boolean } | null };

export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser?: { id: UserId, email: string, firstName?: string | null, lastName?: string | null, createdAt: DateTime, phoneNumber?: string | null, currency?: Types.Currency | null, isMember: boolean, tutorials?: { green: boolean, amber: boolean, red: boolean, carbonNegative: boolean } | null } | null };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  firstName
  lastName
  createdAt
  phoneNumber
  currency
  isMember
  tutorials {
    ...Tutorials
  }
}
    ${TutorialsFragmentDoc}`;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }