import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { CurrencySelectComponent } from './currency-select.component';

@NgModule({
  declarations: [CurrencySelectComponent],
  imports: [CommonModule, MatSelectModule],
  exports: [CurrencySelectComponent],
})
export class CurrencySelectModule {}
