import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Currency } from '@generated/types';
import { CurrencyService } from '../../services/currency/currency.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySelectComponent {
  readonly currencies = Currency;

  readonly currency$ = this.currencyService.currency$;

  constructor(private readonly currencyService: CurrencyService) {}

  async setCurrency(currency: Currency): Promise<Currency> {
    this.currencyService.set(currency);
    return currency;
  }
}
