import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error?: Error): void {
    console.error(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
