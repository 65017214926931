<nav>
  <a class="logo" routerLink="/">
    <svg class="zag" role="img" alt="Staze" aria-hidden="true">
      <use href="/assets/icons/icons.svg#zag"/>
    </svg>
  </a>

  <ng-container *ngIf="layout.config$ | async as config">
    <ul role="navigation" *ngIf="config.showMenu">
      <li>
        <a routerLink="/"
          [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="is-active"
          aria-label="Home"
          class="route">
          <svg role="img" alt="" aria-hidden="true">
            <use href="/assets/icons/icons.svg#home"/>
          </svg>
        </a>
      </li>

      <li>
        <a routerLink="/bookings"
          [class.is-active]="doesPathMatch(['/bookings'])"
          aria-label="Bookings"
          class="route">
          <svg role="img" alt="" aria-hidden="true">
            <use href="/assets/icons/icons.svg#calendar"/>
          </svg>
        </a>
      </li>

      <li>
        <a routerLink="/team"
          routerLinkActive="is-active"
          aria-label="Team"
          class="route team">
          <svg role="img" alt="" aria-hidden="true">
            <use href="/assets/icons/icons.svg#team"/>
          </svg>
        </a>
      </li>
    </ul>

    <div class="bottom">
      <a
        *ngIf="config.showMenu"
        routerLink="/profile"
        routerLinkActive="is-active"
        aria-label="Profile"
        class="route">
        <svg role="img" alt="" aria-hidden="true">
          <use href="/assets/icons/icons.svg#profile"/>
        </svg>
      </a>

      <app-currency-select *ngIf="config.showCurrency"></app-currency-select>
    </div>
  </ng-container>
</nav>

