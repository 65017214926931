<footer>
  <div class="container-xxl">
    <div class="grid">
      <div class="group col-md-6 col-lg-3">
        <ul>
          <li>
            <h5>Company</h5>
            <ul>
              <li>
                <a mat-button [href]="websiteUrl | safeUrl">
                  Overview
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/sustainability' | safeUrl">
                  Sustainability
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/features' | safeUrl">
                  Features
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/our-mission' | safeUrl">
                  Our Mission
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/blog' | safeUrl">
                  Blog
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/help' | safeUrl" title="Help center">
                  FAQs
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="group col-md-6 col-lg-3">
        <ul>
          <li>
            <h5>Legal</h5>
            <ul>
              <li>
                <a mat-button [href]="websiteUrl + '/privacy-policy' | safeUrl">
                  Privacy policy
                </a>
              </li>
              <li>
                <a mat-button [href]="websiteUrl + '/terms-and-conditions' | safeUrl">
                  Terms & conditions
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <a class="logo" routerLink="/">
      <img src="assets/staze-logo-negative.svg" />
    </a>

    <div class="copyright">
      <p class="mat-caption">
        Copyright © Staze Ltd. 2019-2022. Staze Ltd, 25 Horsell Rd, London, N5 1XL.
        All rights reserved.
      </p>
    </div>
  </div>
</footer>
