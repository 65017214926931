import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmissionsColorPipe } from './emissions-color.pipe';
import { EnumeratePipe } from './enumerate.pipe';
import { LengthOfStayPipe } from './length-of-stay.pipe';
import { MoneyPipe } from './money/money.pipe';
import { PhonePipe } from './phone.pipe';
import { PriceCommissionPipe } from './price-commission.pipe';
import { PriceVATPipe } from './price-vat.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    EmissionsColorPipe,
    EnumeratePipe,
    LengthOfStayPipe,
    MoneyPipe,
    PhonePipe,
    PriceCommissionPipe,
    PriceVATPipe,
    SafeUrlPipe,
    TruncatePipe,
  ],
  exports: [
    EmissionsColorPipe,
    EnumeratePipe,
    LengthOfStayPipe,
    MoneyPipe,
    PhonePipe,
    PriceCommissionPipe,
    PriceVATPipe,
    SafeUrlPipe,
    TruncatePipe,
  ],
  providers: [
    CurrencyPipe,
    EmissionsColorPipe,
    EnumeratePipe,
    LengthOfStayPipe,
    MoneyPipe,
    PhonePipe,
    PriceCommissionPipe,
    PriceVATPipe,
    SafeUrlPipe,
    TruncatePipe,
  ],
})
export class PipesModule {}
