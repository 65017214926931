import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@shared/modules/analytics/analytics.service';
import { of, ReplaySubject } from 'rxjs';
import { filter, map, share, switchMap, takeUntil } from 'rxjs/operators';
import { CurrentUserGQL } from 'shared/operations/user.generated';
import { AuthService } from '../services/auth/auth.service';
import { LayoutService } from '../services/layout/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  private readonly destroy$ = new ReplaySubject<void>(1);

  private readonly navigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    share(),
  );

  constructor(
    private readonly analytics: AnalyticsService,
    private readonly router: Router,
    private readonly currentUser: CurrentUserGQL,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    readonly layout: LayoutService,
  ) {
    this.navigationEnd$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      let childSegment = this.route.snapshot.firstChild;
      const paths: string[] = [];

      while (childSegment) {
        if (childSegment.routeConfig?.path) {
          paths.push(childSegment.routeConfig.path);
        }
        childSegment = childSegment.firstChild;
      }

      this.analytics.trackPageView({
        path: '/' + paths.join('/'),
        params: this.route.snapshot.firstChild?.params ?? {},
        queryParams: this.route.snapshot.firstChild?.queryParams ?? {},
      });
    });

    this.auth.authState$
      .pipe(
        filter(Boolean),
        switchMap(() => {
          // Check if the user is already cached first (ie. in a guard)
          return this.currentUser.fetch({}, { fetchPolicy: 'cache-only' }).pipe(
            switchMap(cached => {
              if (!cached.data.currentUser) {
                return this.currentUser.watch(
                  {},
                  { fetchPolicy: 'network-only' },
                ).valueChanges;
              }

              return of(cached);
            }),
          );
        }),
        map(res => res.data.currentUser),
        takeUntil(this.destroy$),
      )
      .subscribe(user => {
        this.analytics.user = user ?? undefined;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
