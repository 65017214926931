import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  GoogleMapsService,
  GoogleMapsServiceConfig,
  GOOGLE_MAPS_SERVICE_CONFIG,
} from './google-maps.service';

@NgModule({
  imports: [HttpClientModule, HttpClientJsonpModule],
})
export class GoogleMapsModule {
  static withOptions(
    config: GoogleMapsServiceConfig,
  ): ModuleWithProviders<GoogleMapsModule> {
    return {
      ngModule: GoogleMapsModule,
      providers: [
        GoogleMapsService,
        {
          provide: GOOGLE_MAPS_SERVICE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
