import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AnalyticsService,
  AnalyticsServiceConfig,
  ANALYTICS_SERVICE_CONFIG,
} from './analytics.service';

@NgModule()
export class AnalyticsModule {
  static withOptions(
    config: AnalyticsServiceConfig,
  ): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        AnalyticsService,
        {
          provide: ANALYTICS_SERVICE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
