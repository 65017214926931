import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnalyticsModule } from '@shared/modules/analytics/analytics.module';
import { GoogleMapsModule } from '@shared/modules/google-maps/google-maps.module';
import { IpLocationModule } from '@shared/modules/ip-location/ip-location.module';
import { SeoModule } from '@shared/modules/seo/seo.module';
import { ApolloModule } from 'apollo-angular';
import { environment } from '../environments/environment';
import { NavBarModule } from '../nav-bar/nav-bar.module';
import { AuthModule } from '../services/auth/auth.module';
import { FooterModule } from '../shared-components/footer/footer.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './error-handler/error-handler';
import { GraphQLModule } from './graphql.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AnalyticsModule.withOptions(environment),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ApolloModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FooterModule,
    GoogleMapsModule.withOptions(environment),
    GraphQLModule,
    HttpClientJsonpModule,
    HttpClientModule,
    IpLocationModule.withOptions(environment),
    LayoutModule,
    NavBarModule,
    SeoModule,
  ],
  providers: [
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.isLocal ? ['http://localhost:9099'] : undefined,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
