import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limitArg?: number, trailArg?: string): string {
    const limit = limitArg || 20;
    const trail = trailArg || '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
