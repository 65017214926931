import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Money } from '@generated/types';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: Money, format?: string): string {
    return (
      this.currencyPipe.transform(
        value.amount,
        value.currency,
        'symbol-narrow',
        format ?? (Number.isInteger(value.amount) ? '1.0' : '1.2-2'),
      ) || ''
    );
  }
}
