import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AnalyticsService } from '@shared/modules/analytics/analytics.service';
import { Apollo } from 'apollo-angular';

/** Handles authentication. Wrapper for AngularFireAuth. */
@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly user$ = this.auth.user;
  readonly authState$ = this.auth.authState;

  constructor(
    private readonly auth: AngularFireAuth,
    private readonly apollo: Apollo,
    private readonly analytics: AnalyticsService,
  ) {}

  async logout(): Promise<void> {
    await this.auth.signOut();
    await this.apollo.client.resetStore();
    this.analytics.reset();
  }
}
