import { ReactiveListener } from '@apollo/client/cache/inmemory/reactiveVars';
import { makeVar, ReactiveVar } from '@apollo/client/core';
import { TutorialsFragment } from '@shared/operations/fragments/tutorials.generated';

export enum LocalStorageKey {
  // Used in the currency-select/service
  Currency = 'currency',
  Tutorials = 'tutorials',
}

const storedTutorials = localStorage.getItem(LocalStorageKey.Tutorials);

export const tutorialsVar = makeVar<TutorialsFragment | null>(
  storedTutorials ? JSON.parse(storedTutorials) : null,
);

subscribeToReactiveVar(tutorialsVar, (value: TutorialsFragment | null) => {
  if (value) {
    localStorage.setItem(LocalStorageKey.Tutorials, JSON.stringify(value));
  } else {
    localStorage.removeItem(LocalStorageKey.Tutorials);
  }
});

/**
 * A helper method to subscribe to ReactiveVar changes as the onNextChange fires
 * once and then unsubscribes.
 *
 * https://github.com/apollographql/apollo-client/pull/7510
 */
function subscribeToReactiveVar<T>(
  rv: ReactiveVar<T>,
  onChange: ReactiveListener<T>,
) {
  let mute;

  function subscribe(value: T) {
    onChange(value);
    mute = rv.onNextChange(subscribe);
  }

  mute = rv.onNextChange(subscribe);

  return mute;
}
