import { dateTimeTypePolicy } from '../scalars/date-time';

export const scalarTypePolicies = {
  Booking: {
    fields: {
      arrivalDate: dateTimeTypePolicy,
      createdAt: dateTimeTypePolicy,
      departureDate: dateTimeTypePolicy,
    },
  },
  BookingRequest: {
    fields: {
      arrivalDate: dateTimeTypePolicy,
      createdAt: dateTimeTypePolicy,
      departureDate: dateTimeTypePolicy,
    },
  },
  Note: {
    fields: { endDate: dateTimeTypePolicy, startDate: dateTimeTypePolicy },
  },
  User: { fields: { createdAt: dateTimeTypePolicy } },
};
